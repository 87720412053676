import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { forEach, toLower, values } from "lodash";
import { navigate, Link as RouterLink } from "@reach/router";
import { Layout } from "~/Layout";
import TouchButton from "~/common/TouchButton";
import getCrumbs from "~/Layout/crumbs";
import DESTINATIONS from "~/Destination/destinations";
import WeightCalculator from "~/Summary/WeightCalculator";
import { Redirect } from "@reach/router";
import { FormControl, TextField } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const destinationColorStyles = () => {
  const styles = {};
  forEach(DESTINATIONS, ({ slug, color }) => {
    styles[slug] = {
      color,
    };
  });
  return styles;
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    overflow: "auto",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    backgroundColor:'orange'
  },
  title: {
    textTransform: "uppercase",
    marginTop: 20,
  },
  text: {
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  smallText: {
    position: "relative",
    fontSize: "1.2rem",
    fontWeight: "bold",
    width: "40vw",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
      justifyContent: "flex-start",
    },
  },
  bigText: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  notesWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8%",
    },
  },
  validationNote: {
    marginTop: "0",
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "black",
    width: "40vw",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  },
  textField: {
    margin: "0",
    padding: "4px",
    width: "40vw",
    height: "15vh",
    overflow: "auto",
    border: "1.5px solid black",
    borderRadius: "6px",
  },
  exceedingCharacters: {
    marginTop: "0",
    fontSize: "0.8rem",
    fontWeight: "bold",
    width: "40vw",
    display: "flex",
    justifyContent: "flex-end",
    color: "#DA5246",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  },
  submit: {
    minHeight: 65,
    marginTop: 30,
    [theme.breakpoints.up("sm")]: {
      paddingRight: 40,
      position: "absolute",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  submitWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      width: "calc(100% - 60px)",
      bottom: 120,
    },
  },
  icon: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: -10,
    },
  },
  green: {
    color: "#347462",
  },
  red: {
    color: "#DA5246",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  ...destinationColorStyles(),
}));

const wasteTypeMessage = (wasteEntry, t) => {
  if (wasteEntry.kind === "RED_KITCHEN_WASTE") {
    return t(`wasteDescription.RED_KITCHEN_WASTE.${wasteEntry.whyTossed}`);
  }
  return t(`wasteDescription.${wasteEntry.kind}`);
};

const Summary = ({
  saveWasteEntry,
  wasteEntry,
  appSettings: { unitSystem },
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["summary_page", "destination_page"]);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const submit = (notes) => {
    saveWasteEntry(notes);
    navigate("/app");
  };

  const validate = (values) => {
    const errors = {};
    return errors;
  };

 
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain");
    const truncatedText = pastedText.substring(0, 100);
    const remainingChars = 100 - values.notes.length;
    const newNotes =
      values.notes.substring(0, e.target.selectionStart) +
      truncatedText.substring(0, remainingChars) +
      values.notes.substring(e.target.selectionEnd);
    handleChange({
      target: {
        name: "notes",
        value: newNotes,
      },
    });
  };

  React.useEffect(() => {
    // Save temporarily the current language to show the correct language
    // in the WasteEntry sagas showAppMessage
    localStorage.setItem("lngTemp", i18n.language);
    window.scrollTo(0, 0);
  }, [i18n.language]);

  const weightCalculator = new WeightCalculator(wasteEntry);

  const colorForWasteTypeMessage =
    wasteEntry.kind === "TRIM_BONES_SHELLS" ? "green" : "red";

  // todo: after submit, if you go back, this will error because we clear out the wasteEntry
  // should do a quick representative check and redirect to home if it fails
  return wasteEntry.container ? (
    <Layout
      crumbs={getCrumbs(
        "home/kindOfWaste/whyTossed/destination/container/quantity/typeOfWaste/summary"
      )}
      previousScreen="/typeOfWaste"
    >
      <Formik
        initialValues={{
          notes: "",
        }}
        validationSchema={Yup.object().shape({
          notes: Yup.string().max(100, "Maximum 100 characters allowed"),
        })}
        onSubmit={(values) => submit(values.notes)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <Typography
                variant="h2"
                align="center"
                className={classes.title}
                gutterBottom
              >
                {t("title")}
              </Typography>

              <Typography align="center" gutterBottom className={classes.text}>
                {t("record")}{" "}
                <Link
                  component={RouterLink}
                  to={
                    wasteEntry.quantity.type === "exact"
                      ? "/exactQuantity"
                      : "/typeOfWaste"
                  }
                  className={clsx(classes.bigText, classes.link)}
                >
                  {weightCalculator.getWeight(unitSystem).toFixed(2)}{" "}
                  {unitSystem === "metric" ? "kg" : "lbs"}
                </Link>
              </Typography>
              <Typography align="center" gutterBottom className={classes.text}>
                {t("of")}{" "}
                <Link
                  component={RouterLink}
                  to="/kindOfWaste"
                  className={clsx(
                    classes.bigText,
                    classes.link,
                    classes[colorForWasteTypeMessage]
                  )}
                >
                  {wasteTypeMessage(wasteEntry, t)}
                </Link>
              </Typography>
              <Typography align="center" gutterBottom className={classes.text}>
                {t("going")}{" "}
                <Link
                  component={RouterLink}
                  to="/destination"
                  className={clsx(
                    classes.bigText,
                    classes.link,
                    classes[wasteEntry.destination]
                  )}
                >
                  {toLower(
                    t(
                      `destination_page:buttons.${wasteEntry.destination}.title`
                    )
                  )}
                </Link>
                .
              </Typography>

              <Box className={classes.notesWrapper}>
                <Typography className={classes.smallText}>
                  {t("noteLabel")}
                </Typography>
                <FormControl>
                  <TextField
                    id="notes"
                    name="notes"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    className={classes.textField}
                    multiline
                    placeholder={t("placeholder")}
                    value={values.notes.slice(0, 100)}
                    onChange={(e) => {
                      if (e.target.value.length <= 100) {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                    error={errors.notes}
                    onPaste={(e) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData.getData("text/plain");
                      const truncatedText = pastedText.substring(0, 100);
                      const remainingChars = 100 - values.notes.length;
                      const newNotes =
                        values.notes.substring(0, e.target.selectionStart) +
                        truncatedText.substring(0, remainingChars) +
                        values.notes.substring(e.target.selectionEnd);
                      handleChange({
                        target: {
                          name: "notes",
                          value: newNotes,
                        },
                      });
                    }}
                  />
                </FormControl>
                <Typography
                  className={clsx(
                    classes.validationNote,
                    values.notes.length >= 100 && classes.exceedingCharacters
                  )}
                >
                  {values.notes.length >= 100
                    ? t("maxCharacters")
                    : `${values.notes.length}/100`}
                </Typography>
              </Box>

              <Box
                mt={2}
                display="inline-flex"
                justifyContent="flex-end"
                className={classes.submitWrapper}
              >
                <TouchButton
                  onClick={() => {}}
                  fullWidth={xs}
                  className={classes.submit}
                  type="submit"
                >
                  {t("submit")}
                  <ChevronRightIcon className={classes.icon} />
                </TouchButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Layout>
  ) : (
    <Redirect to="/app" noThrow />
  );
};

Summary.defaultProps = {
  wasteEntry: "",
};

Summary.propTypes = {
  appSettings: PropTypes.shape({
    unitSystem: PropTypes.oneOf(["imperial", "metric"]),
  }).isRequired,
  wasteEntry: PropTypes.shape({
    destination: PropTypes.string.isRequired,
    quantity: PropTypes.shape({
      type: PropTypes.oneOf(["percentage", "exact"]),
      amount: PropTypes.number,
      units: PropTypes.string,
      percentage: PropTypes.number,
      count: PropTypes.number,
    }).isRequired,
    whyTossed: PropTypes.string, // can be skipped
    kind: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  saveWasteEntry: PropTypes.func.isRequired,
};

export default Summary;
