import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import RadioControl from '~/common/RadioControl';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';
import TYPES_OF_WASTE from '../typesOfWaste';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 64px)',
    overflow: 'hidden',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
    backgroundColor:'yellow'
  },
  subtitle: {
    position: 'relative',
    top: -30,
  },
  formWrap: {
    position: 'relative',
    top: -30,
  },
}));

const TypeOfWaste = ({
  selectType, type, quantity, submitDelay, kind,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('type_of_waste_page');
  const variant = kind === 'TRIM_BONES_SHELLS' ? 'green' : 'default';

  const handleChange = (event) => {
    selectType({ type: event.target.value });
    setTimeout(() => navigate('/summary'), submitDelay);
  };

  const radio = typeOfWaste => (
    <RadioControl
      title={t(`buttons.${typeOfWaste}.title`)}
      caption={t(`buttons.${typeOfWaste}.caption`)}
      onClick={handleChange}
      value={typeOfWaste}
      name="type"
      checked={type === typeOfWaste}
      inputProps={{ 'aria-label': t(`buttons.${typeOfWaste}.title`) }}
      variant={variant}
    />
  );

  const quantityCrumb = quantity.type === 'exact' ? 'exactQuantity' : 'quantity';
  const previousScreen = quantity.type === 'exact' ? '/exactQuantity' : '/quantity';
  return (
    <Layout crumbs={getCrumbs(`home/kindOfWaste/whyTossed/destination/container/${quantityCrumb}/typeOfWaste`)} previousScreen={previousScreen}>
      <Box className={classes.container}>
        <Typography variant="h2" align="center" gutterBottom>{t('title')}</Typography>
        <Typography variant="subtitle1" align="center" className={classes.subtitle} gutterBottom>{t('caption')}</Typography>
        <FormControl component="fieldset" className={classes.formWrap}>
          <RadioGroup aria-label={t('short_title')} name="kindOfWaste">
            <Grid container direction="row" align="center" justifyContent="center" spacing={1}>
              {TYPES_OF_WASTE.map(typeOfWaste => (
                <Grid item xs={12} sm={6} key={typeOfWaste.slug}>
                  {radio(typeOfWaste.slug)}
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
    </Layout>
  );
};

TypeOfWaste.defaultProps = {
  kind: '',
  type: '',
};

TypeOfWaste.propTypes = {
  kind: PropTypes.string,
  type: PropTypes.string,
  selectType: PropTypes.func.isRequired,
  quantity: PropTypes.shape({
    type: PropTypes.oneOf(['exact', 'percentage']),
  }).isRequired,
  submitDelay: PropTypes.number.isRequired,
};

export default TypeOfWaste;
